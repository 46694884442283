import * as amplitude from '@amplitude/analytics-browser';
let amplitudeInitialized = false;
const clientInstance = amplitude.createInstance();
const initializeAmplitude = async () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
        await clientInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, {
            defaultTracking: {
                formInteractions: false,
                fileDownloads: false,
            },
        }).promise;
    }
    amplitudeInitialized = true;
};
const trackAmplitude = async (eventName, DEFAULT_EVENT_PROPS, data) => {
    try {
        if (!amplitudeInitialized)
            await initializeAmplitude();
        clientInstance.track(eventName, { ...DEFAULT_EVENT_PROPS, ...data });
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};
export { trackAmplitude };
